import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { RolesService } from "../roles.service";
import { Role } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class RoleListResolverService implements Resolve<Role[]> {
    constructor(private tps: RolesService) {}

    resolve(): Observable<Role[]> | Observable<never> {
        return Observable.create((observe: Subscriber<Role[]>) => {
            this.tps.refreshRoles().subscribe((roles: Role[]) => {
                observe.next(roles);
                observe.complete();
            });
        });
    }
}
