import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";
import { RolesTableModule } from "./roles-table/roles-table.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { RoleListComponent } from "./role-list/role-list.component";
import { RoleComponent } from "./role/role.component";
import { RoleFormComponent } from "./role-form/role-form.component";
import { RolePermissionsTableComponent } from "./role-permissions-table/role-permissions-table.component";

// Routes
import { RolesRouting } from "./roles.routing";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        MatMenuModule,
        FontAwesome,
        SharedModule,
        RolesRouting,
        RolesTableModule,
        MatCheckboxModule,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [RoleListComponent, RoleComponent, RoleFormComponent, RolePermissionsTableComponent]
})
export class RolesModule {}
