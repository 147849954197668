import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { RolesService } from "../roles.service";
import { Role } from "src/app/models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TourSteps } from "src/app/constants/tour-steps";
import { TourService } from "ngx-ui-tour-md-menu";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-role-form",
    templateUrl: "./role-form.component.html",
    styleUrls: ["./role-form.component.scss"],
    providers: [TitleCasePipe]
})
export class RoleFormComponent implements OnInit, OnDestroy {
    role = new Role();
    roleId: number;
    roleNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;

    loading = true;
    saving = false;

    newSSORole = false;
    authType = "zm";

    resourcesAccessLevel: string;
    sourcesAccessLevel: string;
    adaptivesAccessLevel: string;
    deliveriesAccessLevel: string;
    reportsAccessLevel: string;
    constants = Constants;

    private rolesSubscription: Subscription;
    tableData = [];

    private tourSteps = TourSteps.roleForm;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private rolesService: RolesService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.roleId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.roleId) {
                this.rolesService.refreshRole(this.roleId, true).subscribe(role => {
                    this.role = Object.assign({}, role);
                    this.prepTable();
                    this.loading = false;
                });
            } else {
                this.prepTable();
                this.loading = false;
            }
        });
    }

    prepTable() {
        this.setTitle();

        // Edit
        if (this.isEdit || this.isClone) {
            for (const permission of this.role.permissions) {
                if (permission.read === 1) permission.read = true;
                else permission.read = false;
                if (permission.write === 1) permission.write = true;
                else permission.write = false;
                if (permission.notifications === 1) permission.notifications = true;
                else permission.notifications = false;

                let resource = this.rolesService.objectTypeToResource(permission.object_type);
                if (this.tableData.find(obj => obj.type === resource)) {
                    const parent = this.tableData.find(obj => obj.type === resource);
                    parent.objects.push(permission);
                } else {
                    this.tableData.push({
                        type: resource,
                        read: false,
                        write: false,
                        notifications: false,
                        objects: [permission]
                    });
                }
            }

            if (this.isClone) this.role.name = "";
        }
        // New
        else {
            const resources = Object.keys(Constants.OBJECT_PERMISSIONS_MAP);
            for (const resource of resources) {
                this.tableData.push({
                    type: resource,
                    read: false,
                    write: false,
                    notifications: false,
                    objects: []
                });

                const section = this.tableData.find(obj => obj.type === resource);
                for (const type of Constants.OBJECT_PERMISSIONS_MAP[resource]) {
                    section.objects.push({
                        object_type: type,
                        read: false,
                        write: false,
                        notifications: false
                    });
                }
            }
        }

        // Set initial section checkbox value
        // TODO: use indeterminateCheckboxes funtion here too?
        for (const section of this.tableData) {
            if (section.objects && section.objects.length) {
                const read = section.objects.filter(obj => obj.read);
                const write = section.objects.filter(obj => obj.write);
                const notify = section.objects.filter(obj => obj.notifications);
                //
                if (read.length === section.objects.length) section.read = true;
                else section.read = false;
                if (write.length === section.objects.length) section.write = true;
                else section.write = false;
                if (notify.length === section.objects.length) section.notifications = true;
                else section.notifications = false;
            }
        }
    }

    setAllCheckboxes(checked: boolean, section, property: string) {
        section[property] = checked;
        section.objects.forEach(obj => {
            if (checked && property === "write") obj.read = true;
            obj[property] = checked;
        });
        if (checked && property === "write") section.read = true;
    }

    indeterminateCheckboxes(section, property: string) {
        if (section.objects && section.objects.length) {
            return section.objects.filter(obj => obj[property]).length > 0 && !section[property];
        }
    }

    updateSectionCheckbox(section, property: string) {
        section[property] = section.objects.every(obj => obj[property]);
    }

    updateObjectReadCheckbox(object) {
        if (object.write) object.read = true;
    }

    setTitle() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("ROLE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.role && this.role.name ? this.role.name : "")
        );
    }

    ngOnInit() {
        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        this.rolesService.refreshRoles();

        this.rolesSubscription = this.rolesService.roles.subscribe((roles: Role[]) => {
            if (this.isEdit) this.roleNames = roles.filter(u => u.id !== this.roleId).map(u => u.name);
            else this.roleNames = roles.map(u => u.name);
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.rolesSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        let allObjects = {};
        for (const section of this.tableData) {
            for (const obj of section.objects) {
                allObjects[obj.object_type] = {
                    read: obj.read ? 1 : 0,
                    write: obj.write ? 1 : 0,
                    notifications: obj.notifications ? 1 : 0
                };
            }
        }

        const model = {
            name: this.role.name,
            resource_tag_id: this.role.resourceTag.id,
            user_ids: (this.role.users || []).map(u => u.id),
            group_ids: (this.role.groups || []).map(g => g.id),
            permissions: allObjects
        };

        if (this.isEdit) {
            const result = await this.rolesService.updateRole(this.roleId, model);
            if (result) {
                this.rolesService.refreshRole(this.roleId, true);
                this.router.navigate([Constants.urls.accountManagement.roles, this.roleId]);
            }
        } else {
            const result = await this.rolesService.addRole(model);
            if (result) this.router.navigate([Constants.urls.accountManagement.roles, result.id]);
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit) this.router.navigate([Constants.urls.accountManagement.roles, this.roleId]);
        else this.router.navigate([Constants.urls.accountManagement.roles]);
    }
}
