<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading" autocomplete="off">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit && !isClone">
                        {{ "CREATE_NEW_ROLE" | translate
                        }}<fa-icon
                            class="ms-2 tourStartButton"
                            icon="question-circle"
                            #tooltip="matTooltip"
                            matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_CREATING_A_ROLE_BY_CLICKING_HERE' | translate }}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            size="xs"
                            (click)="tourService.start(); mixpanelService.sendEvent('start create role feature guide')"
                        ></fa-icon>
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_ROLE" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_ROLE" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group" tourAnchor="firstRoleFormAnchor">
                        <!-- Name -->
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="role.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ roleNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <!-- Tag -->
                    <div class="form-group" tourAnchor="secondRoleFormAnchor">
                        <label for="tag" [ngClass]="{ 'is-invalid': form.submitted && form.controls.tag?.errors }"
                            >{{ "TAG" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <zx-access-tag-select
                            name="tag"
                            [required]="true"
                            [(model)]="role.resourceTag"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.tag?.errors }"
                        ></zx-access-tag-select>
                        <div *ngIf="form.controls.tag?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.tag?.errors.required">{{ "AT_LEAST_1_TAG_IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Roles -->
                    <div class="form-group" tourAnchor="thirdRoleFormAnchor">
                        <label for="permissions">{{ "PERMISSIONS" | translate }} </label>
                        <div class="table-responsive">
                            <table class="table table-sm bordered mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-100" translate>OBJECT</th>
                                        <th scope="col" class="w-50px text-center" translate>READ</th>
                                        <th scope="col" class="w-50px text-center" translate>WRITE</th>
                                        <th scope="col" class="w-50px text-center" translate>NOTIFY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let section of tableData">
                                        <tr class="thead-light">
                                            <td>{{ section.type | uppercase | translate }}</td>
                                            <td class="text-center">
                                                <mat-checkbox
                                                    [checked]="section.read"
                                                    [indeterminate]="indeterminateCheckboxes(section, 'read')"
                                                    (change)="setAllCheckboxes($event.checked, section, 'read')"
                                                    [disabled]="section.write"
                                                >
                                                </mat-checkbox>
                                            </td>
                                            <td class="text-center">
                                                <mat-checkbox
                                                    [checked]="section.write"
                                                    [indeterminate]="indeterminateCheckboxes(section, 'write')"
                                                    (change)="setAllCheckboxes($event.checked, section, 'write')"
                                                >
                                                </mat-checkbox>
                                            </td>
                                            <td class="text-center">
                                                <mat-checkbox
                                                    [checked]="section.notifications"
                                                    [indeterminate]="indeterminateCheckboxes(section, 'notifications')"
                                                    (change)="setAllCheckboxes($event.checked, section, 'notifications')"
                                                >
                                                </mat-checkbox>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="section.objects.length">
                                            <tr>
                                                <td colspan="4" class="p-0">
                                                    <table class="table table-sm table-nested">
                                                        <tbody>
                                                            <tr *ngFor="let object of section.objects">
                                                                <td class="w-100">{{ object.object_type | uppercase | translate }}</td>
                                                                <td class="w-50px text-center">
                                                                    <mat-checkbox
                                                                        name="{{ object.object_type }}_read"
                                                                        [(ngModel)]="object.read"
                                                                        (ngModelChange)="updateSectionCheckbox(section, 'read')"
                                                                        [disabled]="object.write && object.read"
                                                                    ></mat-checkbox>
                                                                </td>
                                                                <td class="w-50px text-center">
                                                                    <mat-checkbox
                                                                        name="{{ object.object_type }}_write"
                                                                        [(ngModel)]="object.write"
                                                                        (ngModelChange)="
                                                                            updateSectionCheckbox(section, 'write'); updateObjectReadCheckbox(object)
                                                                        "
                                                                    ></mat-checkbox>
                                                                </td>
                                                                <td class="w-50px text-center">
                                                                    <mat-checkbox
                                                                        name="{{ object.object_type }}_notifications"
                                                                        [(ngModel)]="object.notifications"
                                                                        (ngModelChange)="updateSectionCheckbox(section, 'notifications')"
                                                                    ></mat-checkbox>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Users -->
                    <div class="form-group" tourAnchor="fourthRoleFormAnchor">
                        <label for="usersSelect">
                            {{ "USERS" | translate }}
                        </label>
                        <zx-users-select name="usersSelect" [(model)]="role.users"></zx-users-select>
                    </div>
                    <!-- Groups -->
                    <div class="form-group mb-0" tourAnchor="fifthRoleFormAnchor">
                        <label for="groupsSelect">
                            {{ "GROUPS" | translate }}
                        </label>
                        <zx-groups-select name="groupsSelect" [(model)]="role.groups"></zx-groups-select>
                    </div>
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
