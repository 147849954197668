import { Component, Input, OnInit } from "@angular/core";
import { Constants } from "src/app/constants/constants";
import { Role } from "src/app/models/shared";
import { RolesService } from "../roles.service";

@Component({
    selector: "app-role-permissions-table",
    templateUrl: "./role-permissions-table.component.html",
    styleUrls: ["./role-permissions-table.component.scss"]
})
export class RolePermissionsTableComponent implements OnInit {
    @Input() role: Role;

    tableData = [];

    constructor(private rolesService: RolesService) {}

    ngOnInit(): void {
        this.prepTableData();
    }

    prepTableData() {
        for (const permission of this.role.permissions) {
            let resource = this.rolesService.objectTypeToResource(permission.object_type);
            if (this.tableData.find(obj => obj.type === resource)) {
                const parent = this.tableData.find(obj => obj.type === resource);
                parent.objects.push(permission);
            } else {
                this.tableData.push({
                    type: resource,
                    read: 0,
                    write: 0,
                    notifications: 0,
                    objects: [permission]
                });
            }
        }

        // Set initial section checkbox value
        // TODO: use indeterminateCheckboxes funtion here too?
        for (const section of this.tableData) {
            if (section.objects && section.objects.length) {
                const read = section.objects.filter(obj => obj.read);
                const write = section.objects.filter(obj => obj.write);
                const notify = section.objects.filter(obj => obj.notifications);
                //
                // read
                if (read.length === section.objects.length) section.read = 2;
                else if (!read.length) section.read = 0;
                else section.read = 1;
                // write
                if (write.length === section.objects.length) section.write = 2;
                else if (!write.length) section.write = 0;
                else section.write = 1;
                // notify
                if (notify.length === section.objects.length) section.notifications = 2;
                else if (!notify.length) section.notifications = 0;
                else section.notifications = 1;
            }
        }
    }
}
