import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";

import { RolesService } from "../roles.service";
import { Role } from "src/app/models/shared";
import { SortEvent, NgbSortableHeader } from "src/app/directives/sortable.directive";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";

@Component({
    selector: "app-role",
    templateUrl: "./role.component.html"
})
export class RoleComponent implements OnInit, OnDestroy {
    role = new Role();
    roleId: number;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    sortedByColumn: string;
    sortedByText: string;
    sortColumn: string;
    sortDirection: string;

    private rolesSubscription: Subscription;
    constants = Constants;

    loading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private rolesService: RolesService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.roleId = parseInt(params.get("id"), 10);
            if (!this.role) return this.cancel();

            this.loading = true;

            this.rolesService
                .refreshRole(this.roleId)
                .pipe(take(1))
                .subscribe(role => {
                    this.prepRole(role);
                    this.loading = false;
                });
        });
    }

    ngOnInit() {
        this.rolesSubscription = this.rolesService.roles.subscribe(roles => {
            const role = roles.find(u => u.id === this.roleId);
            if (role) this.prepRole(role);
        });
    }

    ngOnDestroy() {
        if (this.rolesSubscription) this.rolesSubscription.unsubscribe();
    }

    private prepRole(role: Role) {
        this.role = Object.assign({}, role);
        // Set Title
        this.titleService.setTitle(this.translate.instant("ROLE") + " - " + this.role.name);
        //
    }

    cancel() {
        this.gotoRoles();
    }

    gotoRoles() {
        this.router.navigate([Constants.urls.accountManagement.roles]);
    }

    async deleteRole() {
        await this.modalService.confirm(
            "DELETE",
            "ROLE",
            async () => {
                const id = this.role.id;
                const result = await this.rolesService.deleteRole(this.role);
                if (result) {
                    if (this.rolesSubscription) {
                        this.rolesSubscription.unsubscribe();
                        this.rolesSubscription = null;
                    }

                    this.mixpanelService.sendEvent("delete role", { id });
                    this.rolesService.refreshRoles(true);

                    this.gotoRoles();
                } else {
                    return false;
                }
            },
            `${this.role.name}`
        );
    }

    edit(role: Role): void {
        this.router.navigate([Constants.urls.accountManagement.roles, role.id, "edit"]);
    }

    clone(role: Role): void {
        this.router.navigate([Constants.urls.accountManagement.roles, role.id, "clone"]);
    }

    onSort({ column, direction }: SortEvent, name: string) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = "";
            } else {
                header.direction = direction;
            }
        });
        this.sortedByColumn = column;
        this.sortedByText = name;
        this.sortColumn = column;
        this.sortDirection = direction;
    }
}
